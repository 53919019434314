import { useState } from 'react'
import Button from '../Button/Button'

const CookieConsent = () => {
    const [ selected, setSelected ] = useState(false)
    const cookieConsent = document.cookie.includes('cookieConsent=');
    return (
<div className={`${selected || cookieConsent ? 'hidden' : 'content'} z-50 bottom-4 right-4 ml-4 fixed bg-gray-400 p-6 rounded-xl shadow-md max-w-96`}>            <header className='text-lg font-bold'>
                Cookie Consent
            </header>
            <p className="my-4">
                We use cookies to ensure you get the best experience on our website. By continuing to use our site, you agree to our <a href="/privacy-policy">Privacy Policy</a>.
            </p>
            <div className='flex'>
                <Button variant="primary" onClick={() => {
                    document.cookie = 'cookieConsent=true; expires=' + new Date(Date.now() + 90 * 24 * 60 * 60 * 1000).toUTCString();
                    setSelected(true)
                }}>I understand</Button>
                <Button variant="text-link" onClick={() => window.location = '/privacy-policy'}>Learn more</Button>
            </div>
        </div>
    )
}

export default CookieConsent