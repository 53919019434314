import React, { createContext, useState, useCallback, useRef, useEffect, useMemo } from "react";
import { useParams } from 'react-router-dom';
import axios from "../utils/axios";
import { debounce } from 'lodash';
import Spinner from "../components/Spinner/Spinner";
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { customToast } from '../components/CustomToast';

export const PipelineContext = createContext();

export const PipelineProvider = ({ children }) => {
    const [pipeline, setPipeline] = useState(null); // initial state
    const lastSavedPipeline = useRef(null); // initial state
    
    const saveStatus = useRef();

    const updateSaveStatus = (status) => {
        saveStatus.current.updateStatus(status);
    };

    const { pipelineId } = useParams();

    useEffect(() => {
        const getPipeline = async () => {
            try {
                const response = await axios.get(`/pipeline/${pipelineId}`);
                if (response.status === 200) {
                    updatePipelineState(response.data.pipeline);
                    updateSaveStatus({
                        type: "success",
                        message: "Pipeline loaded",
                    });
                } else {
                    console.error(response);
                    updateSaveStatus({
                        type: "error",
                        message: "Failed to load pipeline",
                    });
                }
            } catch (err) {
                console.error(err);
                updateSaveStatus({
                    type: "error",
                    message: "Failed to load pipeline",
                });
            }
        };

        getPipeline();
    }, []);

    // Update both pipeline and lastSavedPipeline with a deep copy of the new pipeline
    const updatePipelineState = (newPipeline) => {
        setPipeline(newPipeline);
        lastSavedPipeline.current = JSON.parse(JSON.stringify(newPipeline)); // Deep copy
    };

    // Called everytime the pipeline is updated
    const autoSave = async () => {
        if (pipeline && lastSavedPipeline) {
            if (JSON.stringify(pipeline) !== JSON.stringify(lastSavedPipeline.current)) {
                updateSaveStatus({
                    type: 'loading',
                    message: 'Autosaving pipeline',
                });

                try {
                    const response = await axios.put('/pipeline', pipeline);

                    if (response.status === 201) {
                        updateSaveStatus({
                            type: 'success',
                            message: 'Pipeline saved',
                        });

                        updatePipelineState(response.data.pipeline);
                    } else {
                        updateSaveStatus({
                            type: 'error',
                            message: 'Failed to save pipeline',
                        });
                        customToast.error('Something went wrong.', 'We were unable to save your pipeline. Please try again later.', { duration: Infinity });
                    }
                } catch (err) {
                    console.error(err);
                    updateSaveStatus({
                        type: 'error',
                        message: 'Failed to save pipeline',
                    });
                    customToast.error('Something went wrong.', 'We were unable to save your pipeline. Please try again later.', { duration: Infinity });
                }
            }
        }
    };

    // Debounce the autoSave function
    const debouncedAutoSave = debounce(() => {
        autoSave();
    }, 1500);

    // Call the debouncedAutoSave function everytime the pipeline is updated
    useEffect(() => {
        if (pipeline && lastSavedPipeline) {
            if (JSON.stringify(pipeline) !== JSON.stringify(lastSavedPipeline.current)) {
                updateSaveStatus({ type: 'loading', message: 'Autosaving pipeline' });
                debouncedAutoSave();
            }
        }

        return () => {
            debouncedAutoSave.cancel();
        };
    }, [pipeline]);

    const runPipeline = async () => {
        try {
            const response = await axios.post(`/pipeline/run`, { id: pipeline._id, name: pipeline.title });
            if (response.status === 200) {
                customToast.success('Pipeline started', `Your pipeline "${pipeline.title}" has started running.`, { duration: 3000 });
            } else {
                console.error(response);
                customToast.error('Something went wrong.', 'We were unable to run your pipeline. Please try again later.', { duration: 5000 });
            }
        } catch (err) {
            console.error(err);

            if (err.response.status === 409) {
                customToast.info('Pipeline is already running', `Your pipeline "${pipeline.title}" is already running.`, { duration: 3000 });
            } else {
                customToast.error('Something went wrong.', 'We were unable to run your pipeline. Please try again later.', { duration: 5000 });
            }
        }
    };

    return (
        <PipelineContext.Provider
            value={{
                pipeline,
                setPipeline,
                updatePipelineState,
                saveStatus,
                updateSaveStatus,
                runPipeline,
            }}
        >
            {children}
        </PipelineContext.Provider>
    );
};
