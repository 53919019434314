import axios from "../../utils/axios";
import { useEffect, useState } from "react";
import { PencilIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

const PipelineOverviewTab = ({ pipeline, setShowRenameModal }) => {
    const statuses = { 
        'Not started': 'text-gray-400 bg-gray-400/30',
        'Running': 'text-indigo-400 bg-indigo-400/30 animate-pulse',
        'Finished': 'text-green-400 bg-green-400/30',
        'Error': 'text-rose-400 bg-rose-400/30'
    };
    const [loadingPipelineRuns, setLoadingPipelineRuns] = useState(false);
    const [pipelineRuns, setPipelineRuns] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        itemsPerPage: 5,
        hasNextPage: false,
        hasPrevPage: false,
    });

    const getPipelineRuns = async (page = 1, limit = 5) => {
        setLoadingPipelineRuns(true);
        try {
            const response = await axios.get(`/pipeline/run-history/${pipeline._id}?page=${page}&limit=${limit}`);
            if (response.status === 200) {
                setPipelineRuns(response.data.pipelineRuns);
                setPagination(response.data.pagination);
            } else {
                console.error(response);
            }
        } catch (err) {
            console.error(err);
        }
        setLoadingPipelineRuns(false);
    };

    useEffect(() => {
        getPipelineRuns(pagination.currentPage, pagination.itemsPerPage);
    }, []);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= pagination.totalPages) {
            getPipelineRuns(newPage, pagination.itemsPerPage);
        }
    };

    const renderPaginationButtons = () => {
        const pageNumbers = [];
        for (let i = 1; i <= pagination.totalPages; i++) {
            pageNumbers.push(i);
        }

        return (
            <nav aria-label="Page navigation">
                <ul className="flex items-center -space-x-px h-8 text-base">
                    <li>
                        <button
                            onClick={() => handlePageChange(pagination.currentPage - 1)}
                            disabled={!pagination.hasPrevPage}
                            className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-transparent dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-transparent dark:disabled:hover:bg-transparent disabled:hover:text-gray-500 dark:disabled:hover:text-gray-400"
                        >
                            <span className="sr-only">Previous</span>
                            <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"/>
                            </svg>
                        </button>
                    </li>
                    {pageNumbers.map((number) => (
                        <li key={number}>
                            <button
                                onClick={() => handlePageChange(number)}
                                aria-current={pagination.currentPage === number ? "page" : undefined}
                                className={`flex items-center justify-center px-3 h-8 leading-tight ${
                                    pagination.currentPage === number
                                        ? "z-10 text-blue-600 border border-blue-300 bg-gray-100 hover:bg-gray-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                                        : "text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white"
                                }`}
                            >
                                {number}
                            </button>
                        </li>
                    ))}
                    <li>
                        <button
                            onClick={() => handlePageChange(pagination.currentPage + 1)}
                            disabled={!pagination.hasNextPage}
                            className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-transparent dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-transparent dark:disabled:hover:bg-transparent disabled:hover:text-gray-500 dark:disabled:hover:text-gray-400"
                        >
                            <span className="sr-only">Next</span>
                            <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                        </button>
                    </li>
                </ul>
            </nav>
        );
    };

    return (
        <div className="flex flex-col gap-y-8">
            <div
                onClick={() => setShowRenameModal(true)}
                className="p-4 rounded-md border border-gray-200 dark:border-gray-700 cursor-pointer group hover:bg-gray-100 dark:hover:bg-gray-800 transition"
            >
                <div className="flex justify-between items-center">
                    <div className="text-xs font-semibold text-gray-500 uppercase">
                        Pipeline description
                    </div>
                    <button className="text-xs text-gray-500 font-semibold uppercase group-hover:text-indigo-600 dark:group-hover:text-indigo-400 flex items-center gap-x-2">
                        <PencilIcon className="h-3 w-3" />
                        <div>Edit</div>
                    </button>
                </div>
                <div className="text-sm mt-4 leading-6">
                    {pipeline.description}
                </div>
            </div>
            <div>
                <div className="border border-gray-200 dark:border-gray-700 rounded-md py-4">
                    <h2 className="px-4 text-xs font-semibold text-gray-500 uppercase">Recent runs</h2>
                    <table className="mt-4 w-full whitespace-nowrap text-left">
                        <colgroup>
                            <col className="w-full sm:w-2/12" />
                            <col className="lg:w-2/12" />
                            <col className="lg:w-1/12" />
                            <col className="lg:w-1/12" />
                        </colgroup>
                        <thead className="border-b border-gray-200 dark:border-white/10 text-sm leading-6 text-gray-700 dark:text-white">
                            <tr>
                                <th scope="col" className="py-2 pl-4 pr-8 font-semibold">
                                    Run ID
                                </th>
                                <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
                                    Status
                                </th>
                                <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                                    Duration
                                </th>
                                <th scope="col" className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell">
                                    Started
                                </th>
                            </tr>
                        </thead>
                        {loadingPipelineRuns ? (
                            <tbody className="divide-y divide-gray-200 dark:divide-white/5">
                                {Array.from({ length: pagination.itemsPerPage }).map((_, index) => (
                                    <tr key={index}>
                                        <td className="py-4 pl-4 pr-8">
                                            <div className="bg-gray-200 dark:bg-gray-700 h-6 w-full rounded-md animate-pulse" />
                                        </td>
                                        <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                                            <div className="bg-gray-200 dark:bg-gray-700 h-6 w-full rounded-md animate-pulse" />
                                        </td>
                                        <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-500 dark:text-gray-400 md:table-cell lg:pr-20">
                                            <div className="bg-gray-200 dark:bg-gray-700 h-6 w-full rounded-md animate-pulse" />
                                        </td>
                                        <td className="hidden py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-500 dark:text-gray-400 sm:table-cell">
                                            <div className="bg-gray-200 dark:bg-gray-700 h-6 w-full rounded-md animate-pulse" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody className="divide-y divide-gray-200 dark:divide-white/5">
                                {pipelineRuns.map((item) => (
                                    <tr key={item.id}>
                                        <td className="py-4 pl-4 pr-8">
                                            <div className="text-sm font-medium leading-6 text-gray-700 dark:text-white">
                                                {item.id}
                                            </div>
                                        </td>
                                        <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                                            <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                                                <time dateTime={item.startTime} className="text-gray-400 sm:hidden">
                                                    {item.startTime}
                                                </time>
                                                <div className={classNames(statuses[item.status], 'flex-none rounded-full p-1')}>
                                                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                                </div>
                                                <div className={`hidden text-gray-700 dark:text-white sm:block ${item.status === 'Running' ? 'animate-pulse' : ''}`}>{item.status}</div>
                                            </div>
                                        </td>
                                        <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-500 dark:text-gray-400 md:table-cell lg:pr-20">
                                            {item.duration}
                                        </td>
                                        <td className="hidden py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-500 dark:text-gray-400 sm:table-cell">
                                            <time dateTime={item.startTime}>{item.started}</time>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
                <div className="mt-4 flex items-center justify-between">
                    <div className="flex-1 text-sm text-gray-700 dark:text-gray-300">
                        Showing <span className="font-medium">{(pagination.currentPage - 1) * pagination.itemsPerPage + 1}</span> to <span className="font-medium">{Math.min(pagination.currentPage * pagination.itemsPerPage, pagination.totalItems)}</span> of{' '}
                        <span className="font-medium">{pagination.totalItems}</span> runs
                    </div>
                    {renderPaginationButtons()}
                </div>
            </div>
        </div>
    );
};

export default PipelineOverviewTab;