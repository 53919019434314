import React, { useState } from "react";
import Button from "../Button/Button";

const PreviewAugmentation = (props) => {
    const { nextStep, previousStep } = props;
    const [isSaving, setIsSaving] = useState(false);

    return (
        <div className="col-span-full">
            <h2 className="text-lg font-medium leading-6 text-gray-900">
                Preview Augmentation Placeholder
            </h2>
            <p className="mt-1 text-sm text-gray-500">
                This is where the preview augmentation component will go
            </p>
            <div className="mt-4 flex justify-left gap-x-4">
                <Button
                    type="submit"
                    variant="primary"
                    loading={isSaving}
                    disabled={false}
                    onClick={nextStep}
                >
                    Start Augmentation
                </Button>
                <Button variant="secondary" onClick={previousStep}>
                    Back
                </Button>
            </div>
        </div>
    );
};

export default PreviewAugmentation;