import Spinner from "../Spinner/Spinner";
import { theme }  from "../../tailwind.theme";

const Button = ({ children, className, variant, size, loading, disabled, iconLeft, iconRight, ...props }) => {
    // Base classes without hover effects
    const baseClasses = `${theme.buttons.classes.base}`;

    // Classes for different sizes
    const normalClasses = `${theme.buttons.classes.large}`;
    const smallClasses = `${theme.buttons.classes.small}`;

    // Variant specific classes
    const primaryClasses = `${baseClasses} ${theme.buttons.types.primary}`;
    const secondaryClasses =  `${baseClasses} ${theme.buttons.types.secondary}`;
    const secondaryAlternateClasses = `${baseClasses} ${theme.buttons.types["secondary-alternate"]}`;
    const tertiaryClasses = `${baseClasses} ${theme.buttons.types.tertiary}`;
    const textLinkClasses = `${baseClasses} ${theme.buttons.types["text-link"]}`;
    const textLinkNoDecorationsClasses = `${baseClasses} ${theme.buttons.types["text-link-nodecorations"]}`;
    const dangerClasses = `${baseClasses} ${theme.buttons.types.danger}`;

    // Classes for disabled state
    const disabledClasses = "cursor-not-allowed";

    let variantClasses = "";

    switch (variant) {
        case "primary":
            variantClasses = disabled ? primaryClasses : `${primaryClasses}`;
            break;
        case "secondary":
            variantClasses = disabled ? secondaryClasses : `${secondaryClasses}`;
            break;
        case "secondary-alternate":
            variantClasses = disabled ? secondaryAlternateClasses : `${secondaryAlternateClasses}`;
            break;
        case "tertiary":
            variantClasses = disabled ? tertiaryClasses : `${tertiaryClasses}`;
            break;
        case "text-link":
            variantClasses = disabled ? tertiaryClasses : `${textLinkClasses}`;
            break;
        case "text-link-nodecorations":
            variantClasses = disabled ? textLinkNoDecorationsClasses : `${textLinkNoDecorationsClasses}`;
            break;
        case "danger":
            variantClasses = disabled ? dangerClasses : `${dangerClasses}`;
            break;
        default:
            variantClasses = disabled ? secondaryClasses : `${secondaryClasses}`;
    }

    let sizeClasses = "";
    let spinnerSize = "";

    switch (size) {
        case "normal":
            sizeClasses = normalClasses;
            spinnerSize = "w-7 h-7";
            break;
        case "small":
            sizeClasses = smallClasses;
            spinnerSize = "w-6 h-6";
            break;
        default:
            sizeClasses = normalClasses;
            spinnerSize = "w-7 h-7";
    }

    let spinnerClasses = "";

    switch (variant) {
        case "primary":
            spinnerClasses = "text-transparent fill-white"
            break;
        case "secondary":
            spinnerClasses = "text-transparent fill-white"
            break;
        case "secondary-alternate":
            spinnerClasses = "text-transparent fill-black"
            break;
        case "tertiary":
            spinnerClasses = "text-transparent fill-black"
            break;
        case "danger":
            spinnerClasses = "text-transparent fill-white"
            break;
        default:
            spinnerClasses = "text-transparent fill-white"
    }

    const combinedClasses = `${variantClasses} ${disabled ? disabledClasses : ''} ${sizeClasses} ${className}`;

    return (
        <button className={`${combinedClasses} flex items-center justify-center`} disabled={disabled} {...props}>
            {iconLeft && (
                <div className="flex-shrink-0 w-4 h-4 mr-1">
                    {!loading && iconLeft}
                </div>
            )}
            <span className={`${loading ? 'invisible' : 'visible'} flex-grow min-w-0`}>
                {children}
            </span>
            {iconRight && (
                <div className="flex-shrink-0 w-4 h-4 ml-1">
                    {!loading && iconRight}
                </div>
            )}
            {loading && (
                <div className="absolute">
                    <Spinner
                        customClasses={spinnerClasses}
                        sizeClasses={spinnerSize}
                    />
                </div>
            )}
        </button>
    );
};

export default Button;
