import React, { createContext, useContext, useState, useEffect } from "react";
import axios from '../utils/axios';
import CookieConsent from "../components/CookieConsent/CookieConsent";
import Button from "../components/Button/Button";

const AuthContext = createContext();
const AuthCheckContext = createContext(() => {});

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  // This is a loading state to prevent the app from rendering before we have a chance to check for the user in local storage
  const [isLoading, setIsLoading] = useState(true);

  // This is the main part of the auth context
  const [user, setUser] = useState(null);

  const [showLogOutModal, setShowLogOutModal] = useState(false);

  // First, let's update the user state with the user object from local storage
  // This will only run once when the component mounts. i.e. a page refresh
  useEffect(() => {
    let userData;
    try {
      userData = JSON.parse(localStorage.getItem('user'));
    } catch (err) {
      userData = null;
    }

    if (userData) {
      setUser(userData);
    }

    setIsLoading(false);
  }, []);

  // Anytime the user state object changes, update local storage
  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      console.log('Removing user from local storage');
      localStorage.removeItem('user');
    }
  }, [user]);

  // Log the user out on the client and server
  // const logOut = async () => {
  //   setShowLogOutModal(true);
  // };

  const clearSession = async () => {
    try {
      await axios.get('/auth/logout');
      setShowLogOutModal(false);
      setUser(null);
      clearToken();
    } catch (err) {
      console.error(err);
    }
  };

  // const LogOutModalComponent = () => {
  //   if (!showLogOutModal) return null;

  //   const closeLogOutModal = () => {
  //     setShowLogOutModal(false);
  //   };

  //   return (
  //     <Modal show={true} allowCloseVeilClick={true} handleClose={closeLogOutModal} size="small">
  //       <h3 className="text-2xl leading-6 font-bold text-white text-left">
  //         Log out
  //       </h3>
  //       <div className="text-left my-8 text-base text-gray-800">
  //         <p>Are you sure you want to log out?</p>
  //       </div>
  //       <div className="flex flex-row-reverse gap-x-4">
  //         <Button onClick={clearSession} variant="primary" size="small">
  //           Log Out
  //         </Button>
  //         <Button onClick={closeLogOutModal} variant="tertiary" size="small">
  //           Cancel
  //         </Button>
  //       </div>
  //     </Modal>
  //   );
  // };

  const setToken = (token) => {
    localStorage.setItem('accesstoken', token);
  };

  const clearToken = () => {
    localStorage.removeItem('accesstoken');
  };


  return (
    <AuthContext.Provider value={{ isLoading, user, setUser, setToken, clearSession }}>
      {children}
      <CookieConsent/>
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth, AuthCheckContext };
