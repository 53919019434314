import { useState, useContext, useEffect, useRef } from "react";
import Button from "../Button/Button";

const TextEntry = ({ entry }) => {
    const [activeRowIndex, setActiveRowIndex] = useState(null);
    const [hoveredTokenIndex, setHoveredTokenIndex] = useState(null);
    const [dragStart, setDragStart] = useState(null);
    const [dragEnd, setDragEnd] = useState(null);
    const [dragRange, setDragRange] = useState([]);
    const [resizeCount, setResizeCount] = useState(0);
    const [resizingSelection, setResizingSelection] = useState(false);

    const [selections, setSelections] = useState([]);

    const parseIndex = (index) => index.split('-').map(Number);

    const calculateRange = (start, end) => {
        const [startRow, startCharIndex] = parseIndex(start);
        const [endRow, endCharIndex] = parseIndex(end);
    
        if (startRow !== endRow) {
            return [];
        }
    
        let rangeArray = [];
        if (startCharIndex > endCharIndex) {
            // Create array from largest to smallest index
            for (let i = startCharIndex; i >= endCharIndex; i--) {
                rangeArray.push(`${startRow}-${i}`);
            }
        } else {
            // Create array from smallest to largest index
            for (let i = startCharIndex; i <= endCharIndex; i++) {
                rangeArray.push(`${startRow}-${i}`);
            }
        }
    
        return rangeArray;
    };

    const handleMouseEnter = (index, parentToken) => {
        const [row, charIndex] = parseIndex(index);
        setActiveRowIndex(row);

        const hoveredTokenId = `${row}-${parentToken?.index}`;
        setHoveredTokenIndex(hoveredTokenId);

        if (resizingSelection) {
            setDragEnd(index);
            const newDragRangeArray = calculateRange(dragStart, index);

            const resizeCharsAlreadySelected = newDragRangeArray.filter(isCharSelected);
            const newCharsToSelect = newDragRangeArray.filter(char => !isCharSelected(char));
            let newResizeCount = newCharsToSelect.length - resizeCharsAlreadySelected.length;

            if (newResizeCount < 0) {
                newDragRangeArray.pop();
            }
            

            // Remove the first char from the drag range
            // newDragRangeArray.shift();

            setResizeCount(newResizeCount);
            setDragRange(newDragRangeArray);

            console.log('dragRange', newDragRangeArray);
            console.log('resizeCount', resizeCount);
        }
    };

    const handleMouseLeave = () => {
        setHoveredTokenIndex(null);

        if (!dragStart) {
            setHoveredTokenIndex(null);
        }
    };

    const handleMouseDown = (charUniqueIndex) => {
        const [row, charIndex] = charUniqueIndex.split('-').map(Number);
        
        // Get the selection that is being resized
        const selectionToResize = selections.find(selection => {
            const { row: selectionRow, startChar, endChar } = selection;
            const isCharSelected = charIndex >= startChar && charIndex < endChar && row === selectionRow;
            return isCharSelected;
        });

        if (selectionToResize && (charIndex === selectionToResize.startChar || charIndex === selectionToResize.endChar - 1)) {
            setResizingSelection(selectionToResize);
            if (!resizingSelection) {
                setDragStart(charUniqueIndex);
            }
        }
    };

    const handleMouseUp = (charUniqueIndex, parentToken) => {
        if (resizingSelection) {
            // Implement logic to resize the existing selection
            const [row, charIndex] = charUniqueIndex.split('-').map(Number);
            const dragStartIndex = dragStart.split('-').map(Number)[1];
            const dragEndIndex = dragEnd.split('-').map(Number)[1];

            let newStartChar = resizingSelection.startChar;
            let newEndChar = resizingSelection.endChar;

            if (dragStartIndex === resizingSelection.startChar) {
                newStartChar = charIndex; // TODO: Make sure it doesn't go past the end of the selection
            } else if (dragStartIndex === resizingSelection.endChar - 1) {
                newEndChar = charIndex + 1; // TODO: Make sure it doesn't go past the end of the selection
            }

            const updatedSelection = {
                ...resizingSelection,
                startChar: newStartChar,
                endChar: newEndChar,
            };

            // Update the selection using the resizingSelection.id to search the selections, selections is an array of objects like updatedSelection with an id
            const updatedSelections = selections.map(selection => {
                if (selection.id === resizingSelection.id) {
                    return updatedSelection;
                } else {
                    return selection;
                }
            });

            setSelections(updatedSelections);
            setResizingSelection(false);
        } else {
            if (!isCharSelected(charUniqueIndex)) {
                // Select the whole token if it's not part of a selection
                // TODO: If the token is already part of a selection, only select the part that is not already selected
                const newSelection = {
                    id: selections.length + 1,
                    row: Number(parentToken.row),
                    startChar: parentToken.start,
                    endChar: parentToken.end,
                    labelId: null
                };
                setSelections([...selections, newSelection]);
            }
        }

        setDragRange([]);

    };

    const isCharSelected = (uniqueIndex) => {
        const [row, charIndex] = uniqueIndex.split('-').map(Number);
        const existsInSelections = selections.some(selection => {
            const { row: selectionRow, startChar, endChar } = selection;
            const isCharSelected = charIndex >= startChar && charIndex < endChar && row === selectionRow;
            return isCharSelected;
        });

        return existsInSelections;
    };

    const isCharInDragRange = (uniqueIndex) => {
        const [row, charIndex] = uniqueIndex.split('-').map(Number);
        const existsInDragRange = dragRange.includes(uniqueIndex);

        return existsInDragRange;
    };

    const isFirstCharInDragRange = (uniqueIndex) => {
        return uniqueIndex === dragRange[0];
    };

    const isLastCharInDragRange = (uniqueIndex) => {
        return uniqueIndex === dragRange[dragRange.length - 1];
    };

    const isFirstCharOfSelection = (uniqueIndex) => {
        const [row, charIndex] = uniqueIndex.split('-').map(Number);
        return selections.some(selection => {
            const { row: selectionRow, startChar } = selection;
            const isCharSelected = charIndex === startChar && row === selectionRow;
            return isCharSelected;
        });
    }

    const isLastCharOfSelection = (uniqueIndex) => {
        const [row, charIndex] = uniqueIndex.split('-').map(Number);
        return selections.some(selection => {
            const { row: selectionRow, endChar } = selection;
            const isCharSelected = charIndex === endChar - 1 && row === selectionRow;
            return isCharSelected;
        });
    }

    const TextRow = ({ text, index }) => {
        const isRowActive = Number(index) === activeRowIndex;
        const tokens = text.match(/(\w+|\W)/g).map((token, tokenIndex, array) => {
            const charStart = tokenIndex === 0 ? 0 : array.slice(0, tokenIndex).join('').length;
            const charEnd = charStart + token.length;
            return {
                row: index,
                index: tokenIndex,
                uniqueIndex: `${index}-${tokenIndex}`,
                token: token,
                start: charStart,
                end: charEnd
            };
        });

        const labelColor = 'bg-indigo-600';
        const labelColorLight = 'bg-indigo-500';
    
        return (
            <div className={`select-none flex flex-row ${isRowActive ? '' : 'opacity-50'}`}>
                <div className="py-4 pr-4 leading-10 font-mono text-base text-gray-500 dark:text-gray-500 border-r border-gray-200 dark:border-gray-700">
                    { index }
                </div>
                <div className={`${resizingSelection ? 'cursor-ew-resize' : 'cursor-pointer'} leading-10 py-4 pl-4 font-mono text-base text-gray-900 dark:text-white`}>
                    <>
                        {text.split('').map((char, charIndex) => {
                            const uniqueIndex = `${index}-${charIndex}`;
                            const parentToken = tokens.find(token => {
                                return charIndex >= token.start && charIndex < token.end;
                            });
                            const isHovered = hoveredTokenIndex === parentToken?.uniqueIndex;
                            let isFirstChar = false;
                            let isLastChar = false;
                            let resizeClass = null;

                            const isSelected = isCharSelected(uniqueIndex);

                            let bgColorClass = 'background-transparent';
                            let borderClass = 'border-transparent';
                            if (isCharInDragRange(uniqueIndex)) {
                                isFirstChar = isFirstCharInDragRange(uniqueIndex);
                                isLastChar = isLastCharInDragRange(uniqueIndex);
                                resizeClass = 'cursor-ew-resize';
                                bgColorClass = labelColorLight;
                            } else if (isSelected) {
                                isFirstChar = isFirstCharOfSelection(uniqueIndex);
                                isLastChar = isLastCharOfSelection(uniqueIndex);
                                resizeClass = isFirstChar || isLastChar ? 'cursor-ew-resize' : null;
                                bgColorClass = labelColor;
                            } else if (isHovered && !resizingSelection) {
                                isFirstChar = charIndex === parentToken?.start;
                                isLastChar = charIndex === parentToken?.end - 1;
                                bgColorClass = '';
                                borderClass = 'border-gray-700';
                            }

                            return (
                                <span
                                    key={uniqueIndex}
                                    onMouseEnter={() => handleMouseEnter(uniqueIndex, parentToken)}
                                    onMouseLeave={handleMouseLeave}
                                    onMouseDown={() => handleMouseDown(uniqueIndex)}
                                    onMouseUp={() => handleMouseUp(uniqueIndex, parentToken)}
                                    className={`relative pt-[7px] pb-[3px] border-b-4 ${resizeClass} ${bgColorClass} ${borderClass}`}
                                >
                                    {char}
                                </span>
                            )
                        })}
                    </>
                </div>
            </div>
        );
    };
    

    return (
        <div>
            {Object.keys(entry).map((key, index) => (
                <TextRow
                    key={key}
                    text={entry[key]}
                    index={key}
                />
            ))}
            {/* <div className="mt-12">
                <Button variant="danger" onClick={() => setSelections([])}>Clear Selections</Button>
            </div> */}
        </div>
    );
};

export default TextEntry;