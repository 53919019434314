
import React, { useContext, useState, useEffect, memo } from 'react';
import { ProjectContext } from "../../context/ProjectContext";

const ProjectSteps = memo((props) => {
    const { project, steps, nextStep, previousStep, getStepStyle, renderIcon, setActiveStep } = useContext(ProjectContext);

    return (
        <div className="pl-3.5">
            <ol className="relative text-gray-500 border-s border-gray-200 dark:border-gray-700">
                {steps.map((step, index) => (
                    <li className="mb-5 ms-8 py-3" key={index} data-active={step.state === "active"}>
                        <a
                            href="#"
                            className={`${step.state !== "active" ? "group" : ""}`}
                            onClick={(e) => setActiveStep(e, index)}
                        >
                            <span
                                className={`absolute flex items-center justify-center w-7 h-7 rounded-full -start-3.5 ring-2 ${getStepStyle(
                                    step.state
                                )}`}
                            >
                                {renderIcon(step.state)}
                            </span>
                            <h3
                                className={`font-medium leading-tight mb-1 ${
                                    step.state === "active"
                                        ? "text-indigo-600 dark:text-indigo-500"
                                        : "group-hover:text-indigo-600 dark:group-hover:text-indigo-500"
                                }`}
                            >
                                {step.title}
                            </h3>
                            <p className="text-sm">{step.description}</p>
                        </a>

                        {step.state === "active" && (
                            <div className="mt-4">{step.component()}</div>
                        )}
                    </li>
                ))}
            </ol>
        </div>
    );
});

export default ProjectSteps;