import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import Button from "../Button/Button";
import { ProjectContext } from "../../context/ProjectContext";

const NameProject = (props) => {
    const { project, setProject, nextStep, previousStep } = useContext(ProjectContext);
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [isFormReady, setIsFormReady] = useState(false);
    const [projectName, setProjectName] = useState(project?.title || "");
    const [projectDescription, setProjectDescription] = useState(project?.description || "");

    const validateForm = () => {
        if (projectName.trim().length === 0) {
            setIsFormReady(false);
        } else {
            setIsFormReady(true);
        }
    };

    useEffect(() => {
        const handleChange = () => {
            if (!projectName) {
                return;
            }

            const newProject = {
                ...project,
                title: projectName,
                description: projectDescription,
            };
    
            setProject(newProject);
        };

        handleChange();
    }, [projectName, projectDescription]);

    useEffect(() => {
        validateForm();
    }, [projectName]);

    return (
        <div className="col-span-full">
            <form>
                <label
                    htmlFor="projectName"
                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                    Project Name {` `}
                    <span className="text-red-500">*</span>
                </label>
                <div className="mt-2 flex rounded-md shadow-sm">
                    <input
                        id="projectName"
                        name="projectName"
                        type="text"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        required
                        className="flex-1 block w-full rounded-md border-gray-300 focus:border-indigo-600 focus:ring-indigo-600 dark:bg-gray-900 dark:border-gray-700 dark:focus:border-indigo-600 dark:focus:ring-indigo-600 dark:text-white"
                    />
                </div>
                <label
                    htmlFor="projectDescription"
                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mt-4"
                >
                    Project Description
                </label>
                <div className="mt-2 flex rounded-md shadow-sm">
                    <textarea
                        id="projectDescription"
                        name="projectDescription"
                        rows="3"
                        value={projectDescription}
                        onChange={(e) => setProjectDescription(e.target.value)}
                        className="flex-1 block w-full rounded-md border-gray-300 focus:border-indigo-600 focus:ring-indigo-600 dark:bg-gray-900 dark:border-gray-700 dark:focus:border-indigo-600 dark:focus:ring-indigo-600 dark:text-white"
                    ></textarea>
                </div>
                <div className="mt-4 flex justify-left gap-x-4">
                    <Button
                        variant="primary"
                        type="button"
                        disabled={!isFormReady}
                        loading={isSaving}
                        onClick={nextStep}
                    >
                        Next: Upload Dataset
                    </Button>
                    <Button variant="secondary" onClick={() => navigate(`/expansions`)}>
                        Cancel
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default NameProject;