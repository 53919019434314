import React from "react";
import useDocumentTitle from "../../utils/pageTitle";
import { CustomToaster } from "../CustomToast";

const Page = ({ children, title }) => {
    const titleSuffix = " - DataExpandr";
    const combinedTitle = title ? `${title}${titleSuffix}` : `DataExpandr`;
    useDocumentTitle(combinedTitle)

    return (
        <>
            {children}
        </>
    );
};

export default Page;
