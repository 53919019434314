import React from 'react';
import ReactDOM from 'react-dom';
import toast, { Toaster } from 'react-hot-toast';
import { XMarkIcon, ExclamationCircleIcon, CheckCircleIcon, XCircleIcon, InformationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const ToastComponent = ({ t, icon, iconWrapperClasses, title, message, duration }) => (
    <div
        className={`${t.visible ? 'animate-enter' : 'animate-leave'
            } max-w-sm w-full bg-white dark:bg-gray-900 shadow-lg rounded-lg pointer-events-auto flex border border-gray-200 dark:border-gray-700 z-50`}
    >
        <div className="flex-1 w-0 p-4">
            <div className="flex items-start h-full items-center">
                <div className={`flex-shrink-0 ${iconWrapperClasses}`}>
                    {icon}
                </div>
                <div className="ml-3 flex-1">
                    <div className="text-sm font-medium text-gray-900 dark:text-gray-100" dangerouslySetInnerHTML={{ __html: title }} />
                    <div className="mt-1 text-sm text-gray-500 dark:text-gray-400" dangerouslySetInnerHTML={{ __html: message }} />
                </div>
            </div>
        </div>
        <div className="flex border-l border-gray-200 dark:border-gray-700">
            <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-gray-600 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-400 focus:outline-none active:bg-gray-200 dark:active:bg-gray-800"
            >
                <XMarkIcon className="h-5 w-5" />
            </button>
        </div>
    </div>
);

const createToast = (icon, iconWrapperClasses) => (title, message, { duration = 5000 } = {}) => {
    toast.custom(
        (t) => (
            <ToastComponent
                t={t}
                icon={icon}
                iconWrapperClasses={iconWrapperClasses}
                title={title}
                message={message}
                duration={duration}
            />
        ),
        {
            duration,
            // This ensures the toast is removed from the DOM after the animation
            onInvisible: (t) => toast.remove(t.id),
        }
    );
};

export const customToast = {
    error: createToast(<ExclamationCircleIcon className="h-6 w-6 text-red-500" />, 'rounded-full bg-red-500/20 p-2'),
    success: createToast(<CheckCircleIcon className="h-6 w-6 text-green-500" />, 'rounded-full bg-green-500/20 p-2'),
    info: createToast(<InformationCircleIcon className="h-6 w-6 text-blue-500" />, 'rounded-full bg-blue-500/20 p-2'),
    warning: createToast(<ExclamationCircleIcon className="h-6 w-6 text-yellow-500" />, 'rounded-full bg-yellow-500/20 p-2'),
};

export const CustomToaster = () => {
    return ReactDOM.createPortal(
        <Toaster
            position="bottom-right"
            reverseOrder={false}
        />,
        document.getElementById('toast-root')
    );
};