import { useContext, useState, useRef } from "react";
import DashboardNav from "../../components/DashboardNav/DashboardNav";
import { PipelineContext } from "../../context/PipelineContext";
import SavingStatus from "../../components/SavingStatus/SavingStatus";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ArrowPathIcon, TrashIcon, PencilIcon, PlayIcon, ClockIcon, ExportIcon, PauseIcon, DocumentDuplicateIcon, ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import Page from "../../components/Page/Page";
import { customToast } from '../../components/CustomToast';
import DeletePipelineModal from "./DeletePipelineModal";
import RenamePipelineModal from "./RenamePipelineModal";

import PipelineOverviewTab from "./PipelineOverviewTab";
import PipelineHistoryTab from "./PipelineHistoryTab";
import PipelineStepsTab from "./PipelineStepsTab";
import PipelineFailureHandlingTab from "./PipelineFailureHandlingTab";
import PipelineNotificationTab from "./PipelineNotificationTab";
import PipelineDebugTab from "./PipelineDebugTab";

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
};

const EditPipelinePage = (props) => {
    const { pipeline, setPipeline, saveStatus, updateSaveStatus, runPipeline } = useContext(PipelineContext);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showRenameModal, setShowRenameModal] = useState(false);

    const pipelineTabs = [
        { name: 'Overview', component: PipelineOverviewTab },
        // { name: 'History', component: PipelineHistoryTab },
        { name: 'Steps', component: PipelineStepsTab },
        { name: 'Failure Handling', component: PipelineFailureHandlingTab },
        { name: 'Notification', component: PipelineNotificationTab },
        { name: 'Debug', component: PipelineDebugTab },
    ];

    const [currentTab, setCurrentTab] = useState(pipelineTabs[0].name);
    const [reloadKey, setReloadKey] = useState(0);

    const handleReloadCurrentTab = () => {
        setReloadKey(prevKey => prevKey + 1);
    };

    const pipelineActionDropdown = [
        { name: 'Rename', action: () => setShowRenameModal(true), icon: PencilIcon },
        { name: 'Edit schedule', action: () => customToast.success('Pipeline schedule updated.', 'Pipeline schedule updated.', { duration: 5000 }), icon: ClockIcon },
        { name: 'Run once', action: () => runPipeline(), icon: PlayIcon },
        { seperator: true },
        { name: 'Delete', action: () => setShowDeleteModal(true), icon: TrashIcon, className: 'text-red-500 dark:text-red-400' },
    ];

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    };

    const PipelineSkeleton = () => (
        <div className="animate-pulse mx-auto">
            {[...Array(5)].map((_, index) => (
                <div key={index} className="flex justify-start items-center gap-x-6 py-5 mb-4" style={{ opacity: (1 - index * 0.2) }}>
                    <div className="flex w-10">
                        <div className={`h-10 w-10 bg-gray-300 dark:bg-gray-700 rounded-full`}></div>
                    </div>
                    <div className="flex w-1/2 gap-x-4">
                        <div className="min-w-96 flex-auto">
                            <div className={`h-5 bg-gray-300 dark:bg-gray-700 rounded w-1/2`}></div>
                            <div className={`mt-3 h-4 bg-gray-300 dark:bg-gray-700 rounded w-11/12`}></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <Page title="Edit Pipeline">
            <div className="min-h-full bg-white dark:bg-gray-900">
                <DashboardNav />
                <header id="pipelinePageHeader" className="h-[70px] sticky top-0 z-10 bg-white dark:bg-gray-900 border-b dark:border-b-gray-700">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex justify-between items-center h-full">
                        <h1 className="text-2xl tracking-tight text-gray-900 dark:text-white flex items-center">
                            {pipeline ? (
                                <div className="-mt-1">
                                    {pipeline.title}
                                </div>
                            ) : (
                                <div className="w-96 h-6 bg-gray-300 dark:bg-gray-700 rounded animate-pulse"></div>
                            )}
                            <Menu as="div" className="relative">
                                <div>
                                    <Menu.Button className="ml-3 flex items-center justify-center rounded-md p-1 hover:bg-gray-100 dark:hover:bg-gray-800 dark:text-gray-400">
                                        <EllipsisHorizontalIcon className="h-6 w-6 text-gray-500 dark:text-gray-400" />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                        <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white py-2 px-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-900 dark:ring-gray-700">
                                            {pipelineActionDropdown.map((item, index) => (
                                                item.seperator ? (
                                                    <div key={'seperator' + index} className="border-t my-2 border-gray-200 dark:border-gray-700" />
                                                ) : (
                                                    <Menu.Item key={item.name}>
                                                        {({ active }) => (
                                                            <Menu.Button
                                                                onClick={item.action}
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 dark:bg-gray-800' : '',
                                                                    'block px-4 py-2 text-sm w-full text-left rounded-md flex items-center gap-x-2',
                                                                    item.className ? item.className : 'text-gray-700 dark:text-gray-300'
                                                                )}
                                                            >
                                                                <item.icon className="h-4 w-4" />
                                                                {item.name}
                                                            </Menu.Button>
                                                        )}
                                                    </Menu.Item>
                                                )
                                            ))}
                                        </Menu.Items>
                                </Transition>
                            </Menu>
                        </h1>
                        <div className="text-sm text-gray-500">
                            <SavingStatus ref={saveStatus} />
                        </div>
                    </div>
                </header>
                <main>
                    <div className="w-full border-b border-gray-200 dark:border-gray-700">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 dark:text-white">
                        <div className="flex w-full">
                            <div className="flex-grow">
                                <div className="sm:hidden">
                                    <label htmlFor="tabs" className="sr-only">
                                        Select a tab
                                    </label>
                                    <select
                                        id="tabs"
                                        name="tabs"
                                        value={currentTab}
                                        className="block w-full rounded-md border-none bg-white/5 my-2 py-1.5 pl-3 pr-10 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm"
                                        onChange={(e) => setCurrentTab(e.target.value)}
                                    >
                                        {pipelineTabs.map((tab) => (
                                            <option key={tab.name}>{tab.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="hidden sm:block">
                                    <ul
                                        role="list"
                                        className="flex min-w-full flex-none gap-x-6 text-sm font-semibold leading-6 text-gray-400"
                                    >
                                        {pipelineTabs.map((tab) => (
                                            <li key={tab.name}>
                                                <button
                                                    onClick={() => setCurrentTab(tab.name)}
                                                    className={classNames(
                                                        currentTab === tab.name
                                                            ? 'border-indigo-500 text-indigo-500'
                                                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-300',
                                                        'transition cursor-pointer whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
                                                    )}
                                                >
                                                    {tab.name}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <button
                                onClick={handleReloadCurrentTab}
                                className="flex items-center gap-x-2 text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 transition ml-4 flex-shrink-0"
                            >
                                <ArrowPathIcon className="h-5 w-5" />
                                <span className="text-sm font-medium">Reload</span>
                            </button>
                        </div>
                        </div>
                    </div>
                    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 dark:text-white">
                        {pipeline ? (
                            <>
                                {pipelineTabs.map((tab) => {
                                    if (tab.name === currentTab) {
                                        return (
                                            <tab.component
                                                key={`${tab.name}-${reloadKey}`}
                                                pipeline={pipeline}
                                                setShowRenameModal={setShowRenameModal}
                                            />
                                        );
                                    }
                                    return null;
                                })}
                            </>
                        ) : (
                            <PipelineSkeleton />
                        )}
                    </div>
                </main>
                <DeletePipelineModal pipeline={pipeline} setShowDeleteModal={setShowDeleteModal} showDeleteModal={showDeleteModal} />
                <RenamePipelineModal pipeline={pipeline} setPipeline={setPipeline} setShowRenameModal={setShowRenameModal} showRenameModal={showRenameModal} />
            </div>
        </Page>
    );
};

export default EditPipelinePage;