
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import { customToast } from '../../components/CustomToast';

import Page from "../../components/Page/Page";
import { Dialog } from "@headlessui/react";
import { Fragment } from 'react';
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Transition } from '@headlessui/react';
import Button from "../../components/Button/Button";
import { useRef, useState } from "react";

const DeletePipelineModal = ({ pipeline, setShowDeleteModal, showDeleteModal }) => {
    const pipelineNameRef = useRef(null);

    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);

    const handleClose = () => {
        setShowDeleteModal(false);
    };

    const handleDeletePipeline = async() => {
        setIsSaving(true);
        try {
            const response = await axios.delete("/pipeline/" + pipeline._id);
            if (response.status === 200) {
                navigate(`/pipelines/`);
            } else {
                console.error(response.statusText);
                customToast.error('Pipeline failed to delete.', 'Please try again later.', { duration: Infinity });
            }
        } catch (err) {
            console.error(err.message);
            customToast.error('Pipeline failed to delete.', 'Please try again later.', { duration: Infinity });

        }
        setIsSaving(false);
    };

    return (
        <Page title="Delete Pipeline">
            <Transition.Root show={showDeleteModal} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-20"
                    initialFocus={pipelineNameRef}
                    onClose={handleClose}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 dark:bg-black dark:bg-opacity-50 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-4 pb-4 pt-7 sm:p-6 sm:pb-4 dark:bg-gray-900">
                                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-xl font-semibold leading-6 text-gray-900 dark:text-white flex items-center gap-x-4"
                                            >
                                                <div className="flex-none rounded-full bg-red-500/20 p-2">
                                                    <ExclamationTriangleIcon className="h-6 w-6 text-red-500" />
                                                </div>
                                                <div>Delete Pipeline</div>
                                            </Dialog.Title>
                                            <div className="mt-7 mb-2">
                                                <p className="text-base text-gray-800 dark:text-white">
                                                    Are you sure you want to delete this pipeline?
                                                </p>
                                                <p className="mt-2 text-base text-gray-500 dark:text-gray-400">
                                                    All data will be permanently deleted and cannot be recovered.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 pt-3 pb-7 flex justify-end sm:px-6 gap-x-4 bg-white dark:bg-gray-900">
                                        <Button
                                            type="button"
                                            variant="secondary"
                                            onClick={() => handleClose(false)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="danger"
                                            type="button"
                                            loading={isSaving}
                                            onClick={handleDeletePipeline}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </Page>
    );
};

export default DeletePipelineModal;