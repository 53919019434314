
import Page from "../../components/Page/Page";
import { Dialog } from "@headlessui/react";
import { Fragment } from 'react';
import { PencilIcon } from "@heroicons/react/24/outline";
import { Transition } from '@headlessui/react';
import Button from "../../components/Button/Button";
import { useRef, useState, useEffect } from "react";

import axios from "../../utils/axios";
import { customToast } from '../../components/CustomToast';
import { useNavigate } from "react-router-dom";

const RenamePipelineModal = ({ pipeline, setPipeline, setShowRenameModal, showRenameModal }) => {
    const pipelineNameRef = useRef(null);
    const pipelineDescriptionRef = useRef(null);

    const [pipelineTitle, setPipelineTitle] = useState();
    const [pipelineDescription, setPipelineDescription] = useState();

    const handleClose = () => {
        setShowRenameModal(false);
    };

    const handleRenamePipeline = async () => {
        setPipeline({ ...pipeline, title: pipelineTitle, description: pipelineDescription });
        handleClose();
    };

    useEffect(() => {
        if (!pipeline) return;

        setPipelineTitle(pipeline.title);
        setPipelineDescription(pipeline.description);
    }, [pipeline]);

    return (
        <Page title="Rename Pipeline">
            <Transition.Root show={showRenameModal} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-20"
                    initialFocus={pipelineNameRef}
                    onClose={handleClose}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 dark:bg-black dark:bg-opacity-50 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-6 pb-6 pt-8 sm:p-8 dark:bg-gray-900">
                                        <div className="text-left">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-2xl font-semibold leading-6 text-gray-900 dark:text-white flex items-center gap-x-4 mb-8"
                                            >
                                                <div className="flex-none rounded-full bg-indigo-500/20 p-2">
                                                    <PencilIcon className="h-6 w-6 text-indigo-600" />
                                                </div>
                                                <div>Rename Pipeline</div>
                                            </Dialog.Title>
                                            <div className="space-y-6">
                                                <div>
                                                    <label htmlFor="pipeline-name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Pipeline name</label>
                                                    <input
                                                        ref={pipelineNameRef}
                                                        type="text"
                                                        className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:text-white dark:placeholder:text-gray-400 dark:border-gray-700 dark:ring-gray-700"
                                                        placeholder="Pipeline name"
                                                        value={pipelineTitle}
                                                        onChange={(e) => setPipelineTitle(e.target.value)}
                                                    />
                                                </div>
                                                <div>
                                                    <label htmlFor="pipeline-description" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Description</label>
                                                    <textarea
                                                        ref={pipelineDescriptionRef}
                                                        id="pipeline-description"
                                                        className="block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:text-white dark:placeholder:text-gray-400 dark:border-gray-700 dark:ring-gray-700"
                                                        placeholder="Pipeline description"
                                                        value={pipelineDescription}
                                                        onChange={(e) => setPipelineDescription(e.target.value)}
                                                        rows={3}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-6 py-5 flex justify-end sm:px-8 gap-x-4">
                                        <Button
                                            type="button"
                                            variant="secondary"
                                            onClick={() => handleClose(false)}
                                            className="px-4 py-2"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            // loading={isSaving}
                                            onClick={handleRenamePipeline}
                                            className="px-4 py-2"
                                        >
                                            Rename
                                        </Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </Page>
    );
};

export default RenamePipelineModal;