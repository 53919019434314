import React, { useState, useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { XCircleIcon } from "@heroicons/react/24/outline";
import Button from "../Button/Button";
import axios from "../../utils/axios";
import { ProjectContext } from "../../context/ProjectContext";

const UploadDataset = (props) => {
    const { project, nextStep, previousStep, updateProjectState, updateSaveStatus } = useContext(ProjectContext);

    const [isSaving, setIsSaving] = useState(false);
    const [isRemovingFile, setIsRemovingFile] = useState(false);
    const [file, setFile] = useState(null);
    const [useManualData, setUseManualData] = useState(false);

    const savedDatasetFilename = project?.datasets?.[0]?.orginalName;

    const onDrop = useCallback((acceptedFiles) => {
        // Assuming you want to handle only the first file
        setFile(acceptedFiles[0]);

        // Automatically upload the file to the server
        uploadDataset(acceptedFiles);
    }, []);

    const config = {
        onDrop,
        accept: {
            "text/csv": [".csv"],
            "text/tab-separated-values": [".tsv", ".tab"],
            "text/jsonl": [".jsonl"],
            "application/json": [".json"],
            "text/plain": [".txt"],
        },
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone(config);

    const uploadDataset = async (acceptedFiles) => {

        updateSaveStatus({
            type: 'loading',
            message: 'Uploading dataset',
        });

        // Create an instance of FormData
        const formData = new FormData();

        // Append the dataset to formData. If you're dealing with multiple files,
        // you might need to loop through `acceptedFiles` and append each one.
        formData.append('dataset', acceptedFiles[0]);

        try {
            // Make an HTTP POST request to your server endpoint
            const response = await axios.post(`/project/${project._id}/dataset`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                updateProjectState(response.data.project);
                updateSaveStatus({
                    type: 'success',
                    message: 'Dataset uploaded',
                });
            } else {
                updateSaveStatus({
                    type: 'error',
                    message: 'Failed to upload dataset',
                });
            }
        } catch (error) {
            // Handle error here
            console.error('Error uploading file:', error);

            updateSaveStatus({
                type: 'error',
                message: 'Failed to upload dataset',
            });
        }
    };

    const removeDataset = async () => {
        setIsRemovingFile(true);

        if (project?.datasets?.length > 0) {
            try {
                updateSaveStatus({
                    type: 'loading',
                    message: 'Removing dataset',
                });
                const deleteResponse = await axios.delete(`/project/${project._id}/dataset`);
                if (deleteResponse.status === 200) {
                    updateProjectState(deleteResponse.data.project);
                    updateSaveStatus({
                        type: 'success',
                        message: 'Dataset removed',
                    });
                }
                console.log(deleteResponse);
            } catch (error) {
                console.error('Failed to remove dataset:', error);
                updateSaveStatus({
                    type: 'error',
                    message: 'Failed to remove dataset',
                });
            }
        }

        setFile(null);
        setIsRemovingFile(false);
    };

    return (
        <div className="col-span-full">
            <form>
                <label
                    htmlFor="cover-photo"
                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                    Dataset <span className="text-red-500">*</span>
                </label>
                <div
                    {...getRootProps()}
                    className="cursor-pointer mt-2 flex justify-center rounded-lg px-6 py-10 border border-dashed border-gray-900/25 dark:border-gray-100/25 hover:border-gray-900/50 dark:hover:border-gray-100/50"
                >
                    <input name="dataset" {...getInputProps()} />
                    <div className="text-center">
                        <DocumentIcon
                            className="mx-auto h-12 w-12 text-gray-300 dark:text-gray-500"
                            aria-hidden="true"
                        />
                        <div className="mt-4 flex text-sm leading-6 text-gray-600 dark:text-gray-400">
                            {file || savedDatasetFilename ? (
                                <p className="text-lg font-medium text-gray-900 dark:text-white mx-auto">
                                    {file?.name || savedDatasetFilename}
                                </p>
                            ) : (
                                <>
                                    <label
                                        htmlFor="file-upload"
                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500 dark:bg-gray-900"
                                    >
                                        <span>Upload a file</span>
                                    </label>
                                    <p className="pl-1">
                                        {isDragActive
                                            ? "Drop the files here ..."
                                            : "or drag and drop"}
                                    </p>
                                </>
                            )}
                        </div>
                        <p className="text-xs leading-5 text-gray-600 dark:text-gray-400">
                            CSV, JSONL, JSON up to 10MB
                        </p>
                        {(file || savedDatasetFilename) && (
                            <Button
                                variant="danger"
                                size="small"
                                loading={isRemovingFile}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    removeDataset();
                                }}
                                icon={<XCircleIcon />}
                                className="mt-4"
                            >
                                Remove file
                            </Button>
                        )}
                    </div>
                </div>
                <div className="mt-4 flex justify-left gap-x-4">
                    <Button
                        type="submit"
                        variant="primary"
                        disabled={!file && !savedDatasetFilename}
                        loading={isSaving}
                        onClick={nextStep}
                    >
                        Next: Data Sample
                    </Button>
                    <Button variant="secondary" onClick={previousStep}>
                        Back
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default UploadDataset;