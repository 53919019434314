import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "./Button/Button";
import { useNavigate } from "react-router-dom";

import axios from "../utils/axios";

const NewExpansionModal = () => {
    const [open, setOpen] = useState(true);
    const projectNameRef = useRef(null);

    const navigate = useNavigate();
    const [isFormReady, setIsFormReady] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [projectName, setProjectName] = useState('');

    const handleFormChange = (e) => {
        setProjectName(e.target.value);
        setIsFormReady(e.target.value.length > 0);
    };

    const handleClose = () => {
        setOpen(false);
        navigate(-1);
    };

    const handleSave = () => {
        setIsSaving(true);
        axios.post("/project/new", { title: projectName })
            .then((response) => {
                // setIsSaving(false);
                // setOpen(false);
                navigate(`/expansions/${response.data.project._id}/edit`);
            })
            .catch((err) => {
                setIsSaving(false);
                console.error(err.message);
            });
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-20"
                initialFocus={projectNameRef}
                onClose={handleClose}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 dark:bg-black dark:bg-opacity-50 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white px-4 pb-4 pt-7 sm:p-6 sm:pb-4 dark:bg-gray-900">
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-semibold leading-6 text-gray-900 dark:text-white"
                                        >
                                            Create a new project
                                        </Dialog.Title>
                                        <div className="mt-4">
                                            <label
                                                htmlFor="projectName"
                                                className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400"
                                            >
                                                Expansion Name {` `}
                                                <span className="text-red-500">
                                                    *
                                                </span>
                                            </label>
                                            <div className="mt-2 flex rounded-md shadow-sm">
                                                <input
                                                    id="projectName"
                                                    name="projectName"
                                                    type="text"
                                                    value={projectName}
                                                    onChange={handleFormChange}
                                                    ref={projectNameRef}
                                                    required
                                                    className="flex-1 block w-full rounded-md border-gray-300 focus:border-indigo-600 focus:ring-indigo-600 bg-transparent dark:border-gray-700 dark:focus:border-indigo-600 dark:focus:ring-indigo-600 dark:text-white"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 pt-3 pb-7 sm:flex sm:justify-between sm:px-6 space-x-4 bg-white dark:bg-gray-900">
                                    <Button
                                        type="button"
                                        variant="secondary"
                                        onClick={() => handleClose(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="primary"
                                        type="button"
                                        disabled={!isFormReady}
                                        loading={isSaving}
                                        onClick={handleSave}
                                    >
                                        Create expansion
                                    </Button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default NewExpansionModal;