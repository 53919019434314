import DashboardNav from "../../components/DashboardNav/DashboardNav";
import Page from "../../components/Page/Page";
import PipelinesList from "./PipelinesList";
import { Link, Outlet } from "react-router-dom";

const PipelineListPage = () => {
    return (
    <Page title="Data Pipelines">
        <div className="min-h-full bg-white dark:bg-gray-900">
            <DashboardNav />
            <header className="h-[70px] sticky top-0 z-10 bg-white dark:bg-gray-900 border-b dark:border-b-gray-700">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex justify-between items-center h-full">
                    <h1 className="-mt-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        Data Pipelines
                    </h1>
                    <div className="flex items-center">
                        <span className="inline-flex rounded-md shadow-sm">
                            <Link
                                to="new"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none transition ease-in-out duration-150"
                            >
                                New Data Pipeline
                            </Link>
                        </span>
                    </div>
                </div>
            </header>
            <main>
                <PipelinesList />
            </main>
            <Outlet />
        </div>
    </Page>
    );
};

export default PipelineListPage;
