import axios from 'axios';

// Token Service for managing access token
class TokenService {
  static getToken() {
    return localStorage.getItem('accesstoken');
  }

  static setToken(token) {
    localStorage.setItem('accesstoken', token);
  }

  static removeToken() {
    localStorage.setItem('accesstoken', '');
  }
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001/',
  withCredentials: true,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const refreshAccessToken = async () => {
  try {
    // console.log('Refreshing access token...');
    const response = await axios.post(`${axiosInstance.defaults.baseURL}auth/refresh-token`, {}, { withCredentials: true });
    const newAccessToken = response.data.accessToken;
    // console.log('Received a new access token from server:', newAccessToken);
    if (newAccessToken) {
      TokenService.setToken(newAccessToken);
    }
    return newAccessToken;
  } catch (error) {
    console.error('Unable to refresh token:', error);
    return null;
  }
};

// Request interceptor for adding the access token to the request header
axiosInstance.interceptors.request.use((request) => {
  const accessToken = TokenService.getToken();
  if (accessToken) {
    request.headers['x-access-token'] = accessToken;
  }

  // Browsers LOVE to cache stuff, especially GET requests, to circumvent this, we can append a timestamp
  const url = new URL(request.url, request.baseURL);
  url.searchParams.set('_t', Date.now());
  request.url = url.toString().replace(url.origin, '');

  return request;
}, (error) => Promise.reject(error));

// Response interceptor for handling token refresh and other errors
axiosInstance.interceptors.response.use((response) => {
  // Check and update token if present in the response
  const newToken = response.headers['x-access-token'];
  if (newToken) {
    TokenService.setToken(newToken);
  }

  const redirect = response.headers['x-redirect'];
  if (redirect) {
    // Before redirecting, make sure the user isn't already at the redirect path
    if (window.location.pathname !== redirect) {
      console.log('Redirecting From Middleware: ', redirect);
      window.location.href = redirect;
    } else {
      console.log('Already at redirect location');
    }
  }

  return response;
}, async (error) => {
  const originalRequest = error.config;

  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(function(resolve, reject) {
        failedQueue.push({ resolve, reject });
      }).then(token => {
        originalRequest.headers['x-access-token'] = token;
        return axiosInstance(originalRequest);
      }).catch(err => {
        return Promise.reject(err);
      });
    }

    originalRequest._retry = true;
    isRefreshing = true;

    try {
      console.log(`Request to ${originalRequest.url} failed with status code ${error.response.status}, attempting to refresh token...`);
      const newAccessToken = await refreshAccessToken();

      if (newAccessToken) {
        originalRequest.headers['x-access-token'] = newAccessToken;
        processQueue(null, newAccessToken);
        return axiosInstance(originalRequest);
      } else {
        processQueue(new Error('Token refresh failed'), null);
        // Token refresh failed, redirect to login
        console.error('Token refresh failed, redirecting to login...');
        TokenService.removeToken();
        localStorage.setItem('user', '');

        const currentPath = window.location.pathname;
        if (currentPath !== '/login') {
          window.location.href = `/login?redirect=${currentPath}`;
        }
      }
    } catch (error) {
      processQueue(error, null);
    } finally {
      isRefreshing = false;
    }
  }

  return Promise.reject(error);
});

export default axiosInstance;
