import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import { ChevronRightIcon, InboxIcon } from "@heroicons/react/24/outline";

const PipelinesList = () => {
    const [pipelines, setPipelines] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPipelines = async () => {
            setLoading(true);
            try {
                const response = await axios.get("/pipeline/all");
                setPipelines(response.data);
            } catch (err) {
                console.error(err.message);
            }
            setLoading(false);
        };
        fetchPipelines();
    }, []);

    const SkeletonLoader = () => (
        <div className="animate-pulse mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <ul role="list" className="divide-y divide-gray-100 dark:divide-gray-800">
                {[...Array(6)].map((_, index) => (
                    <div key={index} className="flex justify-between items-center gap-x-6 py-5" style={{opacity: ( 1 - index * 0.15 )}}>
                        <div className="flex w-96 gap-x-4">
                            <div className="min-w-96 flex-auto">
                                <div className={`h-5 bg-gray-300 dark:bg-gray-700 rounded w-1/2`}></div>
                                <div className={`mt-3 h-4 bg-gray-300 dark:bg-gray-700 rounded w-3/4`}></div>
                            </div>
                        </div>
                        <div className="flex-auto hidden md:flex justify-end w-46">
                            <div className={`h-4 bg-gray-300 dark:bg-gray-700 rounded w-1/4`}></div>
                        </div>
                    </div>
                ))}
            </ul>
        </div>
    );

    const EmptyState = () => (
        <div className="flex flex-col items-center justify-center py-12">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-gray-800">
                <InboxIcon className="h-6 w-6 text-gray-600 dark:text-gray-400" aria-hidden="true" />
            </div>
            <p className="mt-3 text-sm text-gray-500 dark:text-gray-400">
                No data pipelines found
            </p>
        </div>
    );

    const StatusComponent = ({ status }) => {
        const statuseCode = status?.statusCode || 'SETTING_UP'; // Default status code
        const statuses = {
            "SETTING_UP": {
                colorClassBackground: "bg-gray-500/20",
                colorClass: "bg-gray-500",
                text: "Set up in progress",
            },
            "RUNNING": {
                colorClassBackground: "bg-yellow-500/20",
                colorClass: "bg-yellow-500",
                text: "Ready to augment",
            },
            "FINISHED": {
                colorClassBackground: "bg-emerald-500/20",
                colorClass: "bg-emerald-500",
                text: "Augment finished",
            },
            "ERROR": {
                colorClassBackground: "bg-red-500/20",
                colorClass: "bg-red-500",
                text: "Error",
            }
        };

        return (
            <div className="flex items-center space-x-1.5">
                <div className={`flex-none rounded-full ${statuses[statuseCode].colorClassBackground} p-1`}>
                    <div className={`h-1.5 w-1.5 rounded-full ${statuses[statuseCode].colorClass}`} />
                </div>
                <p className="text-xs leading-5 text-gray-500 dark:text-gray-400">
                    {statuses[statuseCode].text}
                </p>
            </div>
        );
    }

    if (loading) {
        return <SkeletonLoader />;
    }

    if (pipelines.length === 0) {
        return <EmptyState />;
    }

    return (
        <ul role="list" className="divide-y divide-gray-100 dark:divide-gray-800">
            {pipelines.map((pipeline) => (
                <li key={pipeline._id} className="hover:bg-gray-50 dark:hover:bg-gray-800">
                    <Link
                        to={`/pipelines/${pipeline._id}/edit`}
                        className="flex justify-between items-center gap-x-6 py-5 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
                    >
                        <div className="flex min-w-0 gap-x-4">
                            <div className="min-w-0 flex-auto">
                                <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
                                    {pipeline.title}
                                </p>
                                <p className="mt-1 truncate text-xs leading-5 text-gray-500 dark:text-gray-400">
                                    {pipeline.description}
                                </p>
                            </div>
                        </div>
                        <div className="flex-auto flex justify-end">
                            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                <StatusComponent status={pipeline.status} />
                                <p className="mt-1 text-xs leading-5 text-gray-500 dark:text-gray-400">
                                    Last modified{" "}
                                    <time dateTime={pipeline.updatedAt}>
                                        {pipeline.lastModified}
                                    </time>
                                </p>
                            </div>
                        </div>
                        <div className="flex-shrink-0 self-center flex">
                            <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                        </div>
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export default PipelinesList;
