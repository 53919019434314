import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../../utils/axios';
import DataExpandrIconIndigo from '../../assets/dataexpandr-icon-indigo-600.svg';
import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import Button from '../../components/Button/Button';
import Page from '../../components/Page/Page';

const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [keepLoggedIn, setKeepLoggedIn] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { setUser } = useAuth();
    const [loginError, setLoginError] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    // Parse the URL search string
    const queryParams = new URLSearchParams(location.search);
    const redirect = queryParams.get('redirect') || '/pipelines';

    const handleLoginError = (err) => {
        console.error(err);
        setLoginError(err);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoginError(null); // Clear any previous login errors

        if (email.trim().length === 0 || password.trim().length === 0) {
            handleLoginError("Email and password cannot be empty.");
            return;
        }

        setIsLoading(true); // start loading

        const delayPromise = new Promise(resolve => setTimeout(resolve, 1000));
        const requestPromise = axios.post('/auth/login', {
            email: email,
            password: password,
            keepLoggedIn: keepLoggedIn,
        });

        try {
            const response = await requestPromise;

            if (response.status === 200) {
                await delayPromise; // If the request finishes before the delay, wait for the delay
                console.log('Login Response:', response.data);
                setUser(response.data.user);
                navigate(redirect, { replace: true });
            } else {
                alert(response.msg);
                await delayPromise; // If the request finishes before the delay, wait for the delay
                setIsLoading(false); // stop loading
            }
        } catch (err) {
            console.error(err);
            handleLoginError('Login failed. Please try again.');
            await delayPromise; // If the request finishes before the delay, wait for the delay
            setIsLoading(false); // stop loading
        }
    };

    return (
        <Page title="Login">
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 dark:bg-gray-900">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        className="mx-auto h-10 w-auto"
                        src={DataExpandrIconIndigo}
                        alt="DataExpandr"
                    />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
                        Sign in to your account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={handleLogin}>
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                            >
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoFocus
                                    tabIndex={1}
                                    required
                                    className="block w-full rounded-md border-gray-300 focus:border-indigo-600 focus:ring-indigo-600 dark:bg-gray-900 dark:border-gray-700 dark:focus:border-indigo-600 dark:focus:ring-indigo-600 dark:text-white"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                                >
                                    Password
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    tabIndex={2}
                                    required
                                    className="block w-full rounded-md border-gray-300 focus:border-indigo-600 focus:ring-indigo-600 dark:bg-gray-900 dark:border-gray-700 dark:focus:border-indigo-600 dark:focus:ring-indigo-600 dark:text-white"
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="keepLoggedIn"
                                    name="keepLoggedIn"
                                    checked={keepLoggedIn}
                                    onChange={(e) => setKeepLoggedIn(e.target.checked)}
                                    type="checkbox"
                                    className="w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-900 focus:ring-2 dark:bg-gray-900 dark:border-gray-700"
                                />
                                <label
                                    htmlFor="keepLoggedIn"
                                    className="ms-2 text-sm font-medium leading-6 text-gray-900 dark:text-white"
                                >
                                    Keep me logged in
                                </label>
                            </div>
                            <div className="text-sm">
                                <a
                                    href="#"
                                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                                >
                                    Forgot password?
                                </a>
                            </div>
                        </div>

                        {loginError && (
                            <div className="text-red-500 text-sm font-semibold mt-2 text-center">
                                {loginError}
                            </div>
                        )}

                        <div>
                            <Button
                                type="submit"
                                variant="primary"
                                size="normal"
                                className="w-full"
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                Sign in
                            </Button>
                        </div>
                    </form>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        New to DataExpandr?{" "}
                        <Link
                            to="/register"
                            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                        >
                            Get started for free
                        </Link>
                    </p>
                </div>
            </div>
        </Page>
    );
};

export default LoginPage;
