import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import DataExpansionsPage from './pages/DataExpansionsPage';
import PipelineListPage from './pages/pipelines/PipelineListPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import { AuthProvider } from "./context/AuthContext";
import PrivateWrapper from "./components/PrivateWrapper/PrivateWrapper";
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import EditProjectPage from './pages/EditProjectPage/EditProjectPage';
import { ProjectProvider } from './context/ProjectContext';
import NewExpansionModal from './components/NewExpansionModal';
import NewPipelineModal from './pages/pipelines/NewPipelineModal';
import PublicWrapper from './components/PublicWrapper/PublicWrapper';
import EditPipelinePage from './pages/pipelines/EditPipelinePage';
import { Navigate } from 'react-router-dom';
import { PipelineProvider } from './context/PipelineContext';
import { CustomToaster } from './components/CustomToast';

const App = () => {
  return (
    <>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<PublicWrapper><LoginPage /></PublicWrapper>} />
            <Route path="/register" element={<PublicWrapper><RegisterPage /></PublicWrapper>} />
            <Route path="/profile" element={<PrivateWrapper><ProfilePage /></PrivateWrapper>} />
            <Route path="/expansions/*" element={
              <PrivateWrapper>
                <Routes>
                  <Route path="/" element={<DataExpansionsPage />}>
                      <Route path="new" element={<NewExpansionModal />} />
                  </Route>
                  <Route path=":projectId/edit" element={
                    <ProjectProvider>
                      <EditProjectPage />
                    </ProjectProvider>
                  } />
                  <Route path="*" element={<NotFoundPage />} /> {/* 404 */}
                </Routes>
              </PrivateWrapper>
            } />
            <Route path="/pipelines/*" element={
              <PrivateWrapper>
                <Routes>
                  <Route path="/" element={<PipelineListPage />}>
                      <Route path="new" element={<NewPipelineModal />} />
                  </Route>
                  <Route path=":pipelineId/edit" element={
                    <PipelineProvider>
                      <EditPipelinePage />
                    </PipelineProvider>
                  } />
                  <Route path="*" element={<NotFoundPage />} /> {/* 404 */}
                </Routes>
              </PrivateWrapper>
            } />
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path="*" element={<NotFoundPage />} /> {/* 404 */}
          </Routes>
        </Router>
      </AuthProvider>
      <CustomToaster />
    </>
  );
}

export default App;
